import type { FC } from 'react';

import Sidebar from '../Sidebar';

const Layout: FC = ({ children }) => {
  return (
    <div className="flex flex-1 text-neutral-100">
      <Sidebar />
      <main className="flex-1 bg-neutral-100 p-2 md:p-4 overflow-hidden flex min-h-screen">
        {children}
      </main>
    </div>
  );
};

export default Layout;

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChangeEvent } from 'react';

export function debounce(func: (...args: any) => void, wait: number) {
  let timeout: any;
  return function d(...args: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const context = this; // eslint-disable-line
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function debounceEventHandler<E extends ChangeEvent<HTMLInputElement>>(
  func: (e: E) => void,
  wait: number,
) {
  const debounced = debounce(func, wait);
  return function d(e: E) {
    e.persist();
    return debounced(e);
  };
}

export enum SortDirection {
  ASCENDING,
  DESCENDING,
}

export const sortBy = <Value, Prop extends keyof Value>(
  values: Value[],
  prop: Prop,
  direction: SortDirection,
) => {
  return [
    ...values.sort(
      (a, b) =>
        (a[prop] < b[prop] ? -1 : 1) *
        (direction === SortDirection.ASCENDING ? 1 : -1),
    ),
  ];
};

export function merge<T>(a: Record<string, T>, b: Record<string, T>) {
  return Object.keys(a).reduce<Record<string, T>>((acc, key) => {
    acc[key] = {
      ...a[key],
      ...b[key],
    };
    return acc;
  }, {});
}

export const throttle = (func: (...args: any) => void, limit: number) => {
  let inThrottle: boolean;
  return function t(...args: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const context = this; // eslint-disable-line
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

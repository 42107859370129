import { PinB } from 'assets/svg';

import { getCurrentYear } from 'utils';

import Icon from '../Icon';

export default () => {
  const year = getCurrentYear();
  return (
    <>
      <footer className="bg-primary-600 text-white">
        <div className="container mx-auto px-2 py-4">
          <PinB className="h-40 mx-auto mb-8" />
          <div className="flex flex-col">
            <div className="flex items-center flex-wrap flex-col md:flex-row">
              <div className="flex flex-1 justify-center items-center">
                <Icon type="map-marker-alt" className="mr-2" />
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/Gravendreef+11%2Fbus+6,+9120+Beveren/@51.2108018,4.2574483,17z/data=!3m1!4b1!4m5!3m4!1s0x47c38b34687ae2ef:0x7387807c612af4!8m2!3d51.2108018!4d4.259637"
                >
                  Gravendreef 11 bus 6, 9120 Beveren
                </a>
              </div>
              <div className="flex flex-1 justify-center items-center my-4 md:my-0">
                <Icon type="phone" className="mr-2" />
                <a className="hover:underline" href="tel:+3232913180">
                  (+32) 3 291 31 80
                </a>
              </div>
              <div className="flex flex-1 justify-center items-center">
                <Icon type="envelope" className="mr-2" />
                <a
                  className="hover:underline"
                  href="mailto:info@partnerinbenefits.be"
                >
                  info@partnerinbenefits.be
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-neutral-100 text-sm">
        <div className="container mx-auto p-2">
          &copy; Partner in Benefits - {year}
        </div>
      </div>
    </>
  );
};

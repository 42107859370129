import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDashboardItem } from '@pib/api/dist/dashboard/types';

import { Card } from '../../common';
import { Icon, Layout } from '../../components';
import { useDashboard } from '../../core/modules/dashboard/hook';

const Dashboard = () => {
  const { dashboard } = useDashboard();
  const { t } = useTranslation();

  const renderGroups = (groups: ApiDashboardItem[]): ReactNode =>
    groups.map((innerGroup, idx) => {
      const infoFiles = (
        <>
          <div className="p-2">
            <h4 className="font-semibold mb-2">{t('dashboard.info.files')}</h4>
            {innerGroup.infoFiles.map(infoFile => {
              if (!infoFile.fileUri) return null;

              return (
                <a
                  key={infoFile.id}
                  className="flex flex-col items-start mb-4 border border-neutral-300 rounded p-4 cursor-pointer hover:bg-neutral-700"
                  href={infoFile.fileUri}
                >
                  <div>
                    <Icon
                      type={infoFile.isImage ? 'image' : 'file-pdf'}
                      className="mr-2"
                    />
                    {infoFile.name}
                  </div>
                  {infoFile.description && (
                    <div title="omschrijving">
                      <Icon type="file-alt" className="mr-2" />
                      {infoFile.description}
                    </div>
                  )}
                  {infoFile.isImage ? (
                    <div className="flex flex-col items-center">
                      <img src={infoFile.fileUri} alt={infoFile.name} />
                    </div>
                  ) : (
                    <div className="cursor-pointer underline text-neutral-600">
                      <Icon type="download" className="mr-2" />
                      {t('dashboard.info.download')}
                    </div>
                  )}
                </a>
              );
            })}
          </div>
          <Card.Separtor />
        </>
      );

      return (
        <Card key={idx} className="m-5 w-1/4 min-w-dashboard-cards  ">
          {innerGroup.name && (
            <>
              <Card.Header title={innerGroup.name} />
              <Card.Separtor />
            </>
          )}
          {innerGroup.infoFiles.length ? infoFiles : null}
        </Card>
      );
    });

  return (
    <Layout>
      <div className="flex flex-col items-center h-full w-full bg-tertiary-500 rounded-lg">
        <h1 className="text-2xl font-bold my-5">Dashboard</h1>
        <div className="flex flex-row items-baseline flex-wrap justify-center">
          {dashboard ? renderGroups(dashboard.items) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;

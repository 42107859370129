import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ListChildComponentProps } from 'react-window';
import { FixedSizeList } from 'react-window';
import clsx from 'clsx';

import { debounceEventHandler, sortBy, SortDirection, useMedium } from 'utils';

import { Icon, Spinner } from '..';

interface Props<Value> {
  children: (value: Value, props: ListChildComponentProps) => ReactNode;
  isLoading: boolean;
  selectedId: string | null;
  isVisible: boolean;
  filterFn: (searchQuery: string) => Value[];
  testId: string;
  title: string;
}

const SearchList = <Value extends { id: string; name: string }>({
  selectedId,
  isVisible,
  isLoading,
  filterFn,
  testId,
  title,
  children,
}: Props<Value>) => {
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState(SortDirection.ASCENDING);
  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<FixedSizeList>();
  const isSmallerThenMedium = useMedium();

  const filteredValues = sortBy(filterFn(searchQuery), 'name', sortDirection);

  useEffect(() => {
    if (selectedId && filteredValues.length) {
      // eslint-disable-next-line
      listRef.current?.scrollToItem(
        filteredValues.findIndex((value: Value) => value.id === selectedId),
        'smart',
      );
    }
  }, [selectedId, filteredValues]);

  const isAscending = sortDirection === SortDirection.ASCENDING;

  return (
    <div
      className={clsx(
        'bg-tertiary-500 md:rounded-l-md md:mr-4 w-employees-card',
        { hidden: !isVisible },
        { block: isVisible },
        isSmallerThenMedium && 'flex-1 w-auto',
      )}
    >
      <div className="flex px-3 py-4 border-b border-neutral-100 items-center">
        <div className="flex-1 font-bold">
          {title} ({filteredValues.length})
        </div>
        <Icon
          type={
            // eslint-disable-next-line
            isLoading
              ? 'circle-notch'
              : isAscending
              ? 'sort-amount-up'
              : 'sort-amount-down'
          }
          spin={isLoading}
          onClick={() =>
            setSortDirection(
              isAscending ? SortDirection.DESCENDING : SortDirection.ASCENDING,
            )
          }
        />
      </div>
      <div className="relative">
        <input
          className="bg-transparent border-b border-neutral-100 w-full px-4 py-2 pr-6 outline-none"
          onChange={debounceEventHandler(
            e => setSearchQuery(e.target.value),
            200,
          )}
          placeholder={t('common.search')}
          data-testid={`${testId}-search`}
        />
        <div className="absolute top-0 right-0 mr-1 mt-2">
          <Icon type="search" />
        </div>
      </div>

      {isLoading && <Spinner />}

      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <FixedSizeList
            width={width}
            height={height}
            itemCount={filteredValues.length}
            itemSize={72}
            ref={listRef as any}
          >
            {(props: ListChildComponentProps) => {
              const value = filteredValues[props.index];
              return children(value, props) as any;
            }}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

export default SearchList;

import type { ApiError } from '@pib/api';

import Alert, { AlertType } from '../Alert';

interface Props {
  error?: Nullable<ApiError>;
}

const ErrorHandler = ({ error }: Props) => {
  return (
    <Alert
      message={error?.message ?? ''}
      type={AlertType.DANGER}
      visible={!!(error && Object.keys(error).length)}
      canClose
    >
      {error?.errors.map((err, idx) => (
        <div key={idx} className="break-words">
          {err}
        </div>
      ))}
    </Alert>
  );
};

export default ErrorHandler;
